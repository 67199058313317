<div class="persons-from-other-analysis-filter">
  <button mat-flat-button [class.has-person-from-analysis]="personFilter.personsFromAnalysisId"
    (click)="toggleUseOtherAnalysis()">
    <div class="use-other-analysis-button">
      @if (personFilter.personsFromAnalysisId ) {
      <mat-icon>link_off</mat-icon> <span>{{ 'PERSON_FILTER.DECOUPLE_ANALYSIS' | translate }}</span>
      } @else{
      <span>{{ 'PERSON_FILTER.COUPLE_ANALYSIS' | translate }}</span>
      }
    </div>
  </button>
  @if (personFilter.personsFromAnalysisId) {
  <div class="select-analysis">
    <div class="small">{{'PERSON_FILTER.SELECT_ANALYSIS_LABEL_1' | translate}}</div>
    <mat-form-field>
      <mat-label></mat-label>
      <mat-select [(ngModel)]="personFilter.personsFromAnalysisId">
        <mat-option [value]="PERSONS_FROM_ANALYSIS_ID_NOT_YET_SELECTED">{{
          'PERSON_FILTER.DO_NOT_USE_PERSONS_FROM_OTHER_ANALYSIS_LABEL' |
          translate}}</mat-option>
        @for (analysis of analysisList; track analysis.analysisId) {
        <mat-option [value]="analysis.analysisId">
          {{analysis.title}}
        </mat-option>
        }
      </mat-select>
    </mat-form-field>
  </div>
  }
</div>

<div class="person-filters">
  <div class="equal-columns">
    <div class="age">
      <div class="header-labels">{{ 'PERSON_FILTER.AGE' | translate}} ({{personFilter.ageMin}} &mdash;
        {{personFilter.ageMax}})</div>
      <mat-slider [min]="MIN_AGE" [max]="MAX_AGE" step="1" discrete>
        <input matSliderStartThumb [(ngModel)]="personFilter.ageMin">
        <input matSliderEndThumb [(ngModel)]="personFilter.ageMax">
      </mat-slider>
    </div>
    <div class="gender-toggle">
      <div class="header-labels">{{ 'PERSON_FILTER.GENDER.TITLE' | translate }}</div>
      <mat-button-toggle-group [hideMultipleSelectionIndicator]="true" [(ngModel)]="personFilter.genders" data-cy="gender-toggle" multiple>
        <mat-button-toggle data-cy="male" value="MALE">{{ 'PERSON_FILTER.GENDER.TYPES.MALE' | translate
          }}</mat-button-toggle>
        <mat-button-toggle data-cy="female" value="FEMALE">{{ 'PERSON_FILTER.GENDER.TYPES.FEMALE' | translate
          }}</mat-button-toggle>
      </mat-button-toggle-group>
    </div>
  </div>
  <div class="equal-columns">
    <div class="possesses-car">
      <div class="header-labels">{{ 'PERSON_FILTER.POSSESSES_CAR.TITLE' | translate }}</div>
      <mat-button-toggle-group [hideMultipleSelectionIndicator]="true" [(ngModel)]="personFilter.possessesCar" multiple>
        <mat-button-toggle data-cy="having-car" value="TRUE">{{ 'PERSON_FILTER.POSSESSES_CAR.TYPES.TRUE' | translate
          }}</mat-button-toggle>
        <mat-button-toggle data-cy="no-car" value="FALSE">{{ 'PERSON_FILTER.POSSESSES_CAR.TYPES.FALSE' | translate
          }}</mat-button-toggle>
        <mat-button-toggle data-cy="unknown-car" value="UNKNOWN">{{ 'PERSON_FILTER.POSSESSES_CAR.TYPES.UNKNOWN' |
          translate
          }}</mat-button-toggle>
      </mat-button-toggle-group>
    </div>
    <div class="has-drivers-license">
      <div class="header-labels">{{ 'PERSON_FILTER.HAS_DRIVERS_LICENSE.TITLE' | translate }}</div>
      <mat-button-toggle-group [hideMultipleSelectionIndicator]="true" [(ngModel)]="personFilter.hasDriversLicense" multiple>
        <mat-button-toggle data-cy="having-driverlicense" value="TRUE">{{ 'PERSON_FILTER.HAS_DRIVERS_LICENSE.TYPES.TRUE'
          |
          translate }}</mat-button-toggle>
        <mat-button-toggle data-cy="no-driverlicense" value="FALSE">{{ 'PERSON_FILTER.HAS_DRIVERS_LICENSE.TYPES.FALSE' |
          translate }}</mat-button-toggle>
        <mat-button-toggle data-cy="unknown-driverlicense" value="UNKNOWN">{{
          'PERSON_FILTER.HAS_DRIVERS_LICENSE.TYPES.UNKNOWN'
          | translate }}</mat-button-toggle>
      </mat-button-toggle-group>
    </div>
  </div>
  <div class="equal-columns">
    <div class="urban-density">
      <mat-form-field>
        <mat-label>{{ 'PERSON_FILTER.URBAN_DENSITY.TITLE' | translate}}</mat-label>
        <mat-select data-cy="urban-density" multiple [(ngModel)]="personFilter.urbanDensity">
          @for (urbanDensity of URBAN_DENSITIES; track urbanDensity) {
          <mat-option [value]="urbanDensity">
            {{'PERSON_FILTER.URBAN_DENSITY.TYPES.' + urbanDensity | translate}}
          </mat-option>
          }
        </mat-select>
      </mat-form-field>
    </div>
    <div class="year-income-type">
      <mat-form-field>
        <mat-label>{{ 'PERSON_FILTER.YEAR_INCOME_TYPES.TITLE' | translate}}</mat-label>
        <mat-select data-cy="gross-income" multiple [(ngModel)]="personFilter.yearIncome">
          @for (yearIncomeType of YEAR_INCOME_TYPES; track yearIncomeType) {
          <mat-option [value]="yearIncomeType">
            {{'PERSON_FILTER.YEAR_INCOME_TYPES.TYPES.' + yearIncomeType | translate}}
          </mat-option>
          }
        </mat-select>
      </mat-form-field>
    </div>
  </div>
  <div class="equal-columns">
    <div class="household-type">
      <mat-form-field>
        <mat-label>{{ 'PERSON_FILTER.HOUSEHOLD_TYPE.TITLE' | translate}}</mat-label>
        <mat-select data-cy="household-type" multiple [(ngModel)]="personFilter.householdType">
          @for (householdType of HOUSEHOLD_TYPES; track householdType) {
          <mat-option [value]="householdType">
            {{'PERSON_FILTER.HOUSEHOLD_TYPE.TYPES.' + householdType | translate}}
          </mat-option>
          }
        </mat-select>
      </mat-form-field>
    </div>
    <div class="household-size">
      <div class="header-labels">{{ 'PERSON_FILTER.HOUSEHOLD_SIZE' | translate}} ({{personFilter.householdSizeMin}}
        &mdash; {{personFilter.householdSizeMax}})</div>
      <mat-slider [min]="MIN_HOUSEHOLDS" [max]="MAX_HOUSEHOLDS" step="1" discrete>
        <input matSliderStartThumb [(ngModel)]="personFilter.householdSizeMin">
        <input matSliderEndThumb [(ngModel)]="personFilter.householdSizeMax">
      </mat-slider>
    </div>
  </div>
  <div class="equal-columns">
    <div class="highest-education-type">
      <mat-form-field>
        <mat-label>{{ 'PERSON_FILTER.HIGHEST_EDUCATION_TYPES.TITLE' | translate}}</mat-label>
        <mat-select data-cy="highest-education" multiple [(ngModel)]="personFilter.highestEducation">
          @for (highestEducation of HIGHEST_EDUCATION_TYPES; track highestEducation) {
          <mat-option [value]="highestEducation">
            {{'PERSON_FILTER.HIGHEST_EDUCATION_TYPES.TYPES.' + highestEducation | translate}}
          </mat-option>
          }
        </mat-select>
      </mat-form-field>
    </div>
    <div class="work-type">
      <mat-form-field>
        <mat-label>{{ 'PERSON_FILTER.OCCUPANCY_TYPES.TITLE' | translate}}</mat-label>
        <mat-select data-cy="occupancy" multiple [(ngModel)]="personFilter.occupancy">
          @for (occupancy of OCCUPANCY_TYPES; track occupancy) {
          <mat-option [value]="occupancy">
            {{'PERSON_FILTER.OCCUPANCY_TYPES.TYPES.' + occupancy | translate}}
          </mat-option>
          }
        </mat-select>
      </mat-form-field>
    </div>
  </div>
</div>
